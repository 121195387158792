import { IonContent, IonPage } from "@ionic/react";

import SplashContainer from "../components/SplashContainer";
import "./Home.css";

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <SplashContainer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
